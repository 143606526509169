export const CONTAINER_CLASS_PER_MAX_WIDTH = {
    "1128px": "",
    "1400px": "g-container--new"
};
export const getContainerClassPerMaxWidth = (maxWidth) => {
    if (typeof maxWidth === "undefined") {
        return "";
    }
    const keyFound = Object.keys(CONTAINER_CLASS_PER_MAX_WIDTH).find(key => key === maxWidth);
    return keyFound ? CONTAINER_CLASS_PER_MAX_WIDTH[keyFound] : "";
};
