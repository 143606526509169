import React, { useCallback, useState } from "react";
import { getClassConditionalAppend, getColors, getContainerClassPerMaxWidth, isValidEmail, isValidName, isValidPhone } from "../../utils";
import { Container, ContainerFW } from "../global";
import { Heading } from "../Heading";
import { FormInputs } from "./FormInputs/FormInputs";
import { Overlay } from "./Overlay/Overlay";
const initialUserData = {
    name: {
        value: "",
        isValid: false,
    },
    email: {
        value: "",
        isValid: false,
    },
    phoneNumber: {
        value: "",
        isValid: false,
    }
};
const initialErrorData = {
    email: undefined,
    phoneNumber: undefined,
    name: undefined,
};
export const M35IntouchForm = ({ subTitle, title, userData, buttonLabel, titleAlignment, titleColor, aubTitleColor, subTitleAlignment, backgroundColor, containerMaxWidth, backgroundImageUrl, emailErrorLabel, phoneErrorLabel, requiredFieldLabel, nameErrorLabel, modalMessage, modalButtonMessage, campaignId, errorModalMessage, sendingInfoMessage }) => {
    const [formData, setFormData] = React.useState(initialUserData);
    const [isLoading, setIsLoading] = useState(false);
    const [errorValue, setErrorValue] = useState(initialErrorData);
    const [currentModalMessage, setCurrentModalMessage] = useState(modalMessage);
    const { bgColor } = getColors(backgroundColor);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    const handleCheck = useCallback((htmlId, value) => {
        let validated;
        if (htmlId === "name") {
            validated = isValidName(value);
            if (!validated) {
                setErrorValue((prev) => ({ ...prev, name: nameErrorLabel }));
                return validated;
            }
            setErrorValue((prev) => ({ ...prev, name: undefined }));
            return validated;
        }
        if (htmlId === "email") {
            validated = isValidEmail(value);
            if (!validated) {
                setErrorValue((prev) => ({ ...prev, email: emailErrorLabel }));
                return validated;
            }
            setErrorValue((prev) => ({ ...prev, email: undefined }));
            return validated;
        }
        validated = isValidPhone(value);
        if (!validated) {
            setErrorValue((prev) => ({ ...prev, phoneNumber: phoneErrorLabel }));
            return validated;
        }
        setErrorValue((prev) => ({ ...prev, phoneNumber: undefined }));
        return validated;
    }, [emailErrorLabel, nameErrorLabel, phoneErrorLabel]);
    const handleUserInput = useCallback((event) => {
        const value = event.target.value;
        const htmlId = event.target.id;
        const isValid = handleCheck(htmlId, value);
        setFormData((prevData) => ({
            ...prevData,
            [htmlId]: {
                value,
                isValid,
            }
        }));
    }, [handleCheck]);
    const constructRequestFormData = React.useCallback(() => {
        const requestFormData = new FormData();
        for (const key in formData) {
            if (key !== "formFile") {
                requestFormData.append(key, formData[key].value);
            }
            else {
                const files = formData[key];
                files.forEach((file) => {
                    requestFormData.append("formFile", file);
                });
            }
        }
        campaignId && requestFormData.append("campaignId", campaignId);
        return requestFormData;
    }, [campaignId, formData]);
    const sendRequest = React.useCallback(async () => {
        setIsLoading(true);
        setCurrentModalMessage(sendingInfoMessage);
        const requestFormData = constructRequestFormData();
        try {
            const r = await fetch("/m35intouchform", {
                method: "POST",
                body: requestFormData
            });
            if (r.status === 200) {
                setCurrentModalMessage(modalMessage);
            }
            else {
                setCurrentModalMessage(errorModalMessage);
            }
        }
        catch {
            setCurrentModalMessage(errorModalMessage);
        }
    }, [constructRequestFormData, errorModalMessage, modalMessage, sendingInfoMessage]);
    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        if (isLoading) {
            return;
        }
        let canSubmit = true;
        const inputValues = Object.keys((formData));
        inputValues.forEach((fieldName) => {
            if (!formData[fieldName].isValid) {
                canSubmit = false;
            }
        });
        inputValues.forEach((a) => (handleCheck(a, formData[a].value)));
        if (!canSubmit) {
            return;
        }
        if (canSubmit) {
            await sendRequest();
        }
        setFormData(initialUserData);
        setErrorValue(initialErrorData);
    }, [formData, handleCheck, isLoading, sendRequest]);
    const handleModalVisibility = useCallback(() => {
        setIsLoading(!isLoading);
    }, [isLoading]);
    const handleErrorValidator = useCallback((event) => {
        const value = event.target.value;
        const htmlId = event.target.id;
        handleCheck(htmlId, value);
    }, [handleCheck]);
    return (React.createElement("div", { className: "in-touch-form-root", style: { "--background-image-url": `url(${backgroundImageUrl})` }, id: "M35-Intouch-Form-identifier" },
        React.createElement(ContainerFW, { className: containerExtraClass, backgroundColor: bgColor },
            React.createElement(Container, { className: containerExtraClass },
                React.createElement("div", { className: "in-touch-form" },
                    (isLoading) && React.createElement(Overlay, { handleVisibility: handleModalVisibility, modalMessage: currentModalMessage, modalButtonMessage: modalButtonMessage }),
                    React.createElement("div", { className: "in-touch-form__textWrapper" },
                        title && React.createElement(Heading, { className: "h2-regular", style: { "--title-text-alignment": titleAlignment, "--title-text-color": titleColor } }, title),
                        React.createElement("p", { className: "in-touch-form-subTitle", style: { "--subTitle-text-alignment": subTitleAlignment, "--subTitle-text-color": aubTitleColor } }, subTitle)),
                    React.createElement(FormInputs, { errorLabel: errorValue, onSubmit: handleSubmit, name: formData.name.value, email: formData.email.value, phoneNumber: formData.phoneNumber.value, emailPlacerHolder: userData.emailPlacerHolder, namePlacerHolder: userData.namePlacerHolder, phoneNumberPlacerHolder: userData.phoneNumberPlacerHolder, inputHandler: handleUserInput, buttonLabel: buttonLabel, validateErrors: handleErrorValidator }))))));
};
