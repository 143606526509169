import React from "react";
import { Img } from "../../Img";
import { Container, ContainerFW } from "../../global";
import { ButtonWrapper } from "./ButtonWrapper/ButtonWrapper";
import { Breadcrumbs } from "../../Breadcrumbs";
import { Heading } from "../../Heading";
import { getContainerClassPerMaxWidth } from "../../../utils";
const getBackgroundColor = (backgroundColor) => backgroundColor === "dark" ? "#0E1D31" : "#fff";
const checkHasComponentButton = ({ buttonHref, buttonLabel, linkHref, linkLabel }) => (buttonHref && buttonLabel) || (linkHref && linkLabel);
const getClassName = (backgroundColor, intrinsicImgSize, evenSetsForm2ColsMobile, numItems) => {
    let className = backgroundColor === "dark" ? "tradeWithComponentBlackBackground-dark" : "tradeWithComponentBlackBackground";
    if (intrinsicImgSize) {
        className += " tradeWithComponentBlackBackground--intrinsic-img-size";
    }
    if (evenSetsForm2ColsMobile && numItems % 2 === 0) {
        className += " tradeWithComponentBlackBackground--two-cols-mobile";
    }
    return className;
};
const getTitle = (title, h2Ttile, mainTitleWidth) => {
    if (title) {
        return !h2Ttile ? React.createElement("h1", { className: "tradeWithComponent-mainTitle", dangerouslySetInnerHTML: { __html: title }, style: { "--main-title-width": mainTitleWidth } }) : React.createElement("h2", { className: "tradeWithComponent-mainTitle", dangerouslySetInnerHTML: { __html: title }, style: { "--main-title-width": mainTitleWidth } });
    }
    else {
        return "";
    }
};
const getContainerExtraClass = (containerMaxWidth) => {
    const containerMaxWidthClass = containerMaxWidth && getContainerClassPerMaxWidth(containerMaxWidth);
    return containerMaxWidthClass ? ` ${containerMaxWidthClass}` : "";
};
export const TradeWithComponentBlackBackground = ({ mainTitle, h2title, subMainTitle, sectionItems, buttonHref, buttonLabel, linkLabel, linkHref, backgroundColor, breadcrumbs, openInNewTabButton, linkOpenInNewTab, containerMaxWidth, subTitleColor, subTitlePosition, subTitleFontWeight, titleColor, titlePosition, titleFontWeight, intrinsicImgSize = false, evenSetsForm2ColsMobile = false, mainTitleWidth }) => {
    const correctedBgColor = getBackgroundColor(backgroundColor);
    const hasButton = checkHasComponentButton({ buttonHref, buttonLabel, linkHref, linkLabel });
    const className = getClassName(backgroundColor, intrinsicImgSize, evenSetsForm2ColsMobile, sectionItems.length);
    const wrapperClass = sectionItems.length <= 3 ? "tradeWithComponent-sectionWrapper" : "tradeWithComponent-sectionWrapper--larger";
    const containerExtraClass = getContainerExtraClass(containerMaxWidth);
    return (React.createElement(ContainerFW, { backgroundColor: correctedBgColor, className: containerExtraClass },
        React.createElement("div", { className: className, style: { "--title-color": titleColor, "--title-position": titlePosition, "--title-font-weight": titleFontWeight, "--subtitle-color": subTitleColor, "--subtitle-position": subTitlePosition, "--subtitle-font-weight": subTitleFontWeight } },
            breadcrumbs && React.createElement("div", { className: "breadcrumbs__container" },
                React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs })),
            React.createElement(Container, { className: containerExtraClass },
                getTitle(mainTitle, h2title, mainTitleWidth),
                subMainTitle && React.createElement("p", { className: "tradeWithComponent-subTitle" }, subMainTitle),
                React.createElement("div", { className: wrapperClass }, sectionItems.length > 0 && sectionItems?.map((a, i) => (React.createElement("span", { key: i, className: "tradeWithComponent-sectionWrapper__section" },
                    React.createElement(Img, { src: a.imageUrl, className: "tradeWithComponent-sectionWrapper__section__image", alt: a.imageAlt }),
                    React.createElement(Heading, { design: "h4-semi-bold" }, a.title),
                    React.createElement("div", { className: "tradeWithComponent-sectionWrapper__section__subTitle", dangerouslySetInnerHTML: { __html: a.subTitle } }))))),
                hasButton && React.createElement(ButtonWrapper, { buttonHref: buttonHref, buttonLabel: buttonLabel, linkHref: linkHref, linkLabel: linkLabel, openInNewTabButton: openInNewTabButton, linkOpenInNewTab: linkOpenInNewTab })))));
};
