import React from "react";
import { getColors, getClassConditionalAppend, getContainerClassPerMaxWidth } from "../../utils";
import { CardWithImageAndGradient } from "./CardWithImageAndGradient/CardWithImageAndGradient";
import { ContainerFW, Container } from "../global";
export const M31FiveCardsTitleText = ({ cards, subTitle, title, backgroundColor, titleAlignment, subTitleAlignment, titleColor = "0E1D31", subTitleColor = "#3E4A5A", containerMaxWidth }) => {
    const { bgColor } = getColors(backgroundColor);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    return (React.createElement("div", { className: "five-cards-title-text-root" },
        React.createElement(ContainerFW, { className: containerExtraClass, backgroundColor: bgColor },
            React.createElement(Container, { className: containerExtraClass },
                React.createElement("div", { className: "five-cards-title-text-root__textWrapper" },
                    React.createElement("div", { className: "h3-semi-bold", dangerouslySetInnerHTML: {
                            __html: title || ""
                        }, style: { "--title-text-alignment": titleAlignment, "--title-text-color": titleColor } }),
                    React.createElement("div", { className: "p-regular", style: { "--subTitle-text-alignment": subTitleAlignment, "--subTitle-text-color": subTitleColor }, dangerouslySetInnerHTML: {
                            __html: subTitle || ""
                        } })),
                React.createElement("div", { className: "five-cards-title-text-root__cardsWrapper" }, Array.isArray(cards) && cards.map((card) => (React.createElement(CardWithImageAndGradient, { key: `unique${card.title}`, ...card }))))))));
};
