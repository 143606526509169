import React from "react";
export const FormInputs = ({ email, name, phoneNumber, emailPlacerHolder, namePlacerHolder, phoneNumberPlacerHolder, inputHandler, buttonLabel, onSubmit, errorLabel, validateErrors }) => (React.createElement("form", { className: "form-inputs-root", onSubmit: onSubmit },
    React.createElement("span", null,
        React.createElement("input", { onChange: inputHandler, value: name, type: "text", name: "name", id: "name", onBlur: validateErrors, placeholder: namePlacerHolder }),
        errorLabel?.name && React.createElement("p", { className: "errorLabel" }, errorLabel.name)),
    React.createElement("span", null,
        React.createElement("input", { onChange: inputHandler, value: email, type: "text", name: "email", id: "email", onBlur: validateErrors, placeholder: emailPlacerHolder }),
        errorLabel?.email && React.createElement("p", { className: "errorLabel" }, errorLabel.email)),
    React.createElement("span", null,
        React.createElement("input", { onChange: inputHandler, value: phoneNumber, type: "text", name: "phoneNumber", id: "phoneNumber", onBlur: validateErrors, placeholder: phoneNumberPlacerHolder }),
        errorLabel?.phoneNumber && React.createElement("p", { className: "errorLabel" }, errorLabel.phoneNumber)),
    React.createElement("button", { type: "submit" }, buttonLabel)));
