import React, { useState, useEffect, useMemo } from "react";
import { Container, ContainerFW } from "../../global";
import { Img } from "../../Img";
import { usePageViewStyle } from "../../../hooks";
import { IconChevronRight } from "../../../Components/svgIcons/chevronRight";
import { StatsValuesRibbon } from "./StatsValuesRibbon/StatsValuesRibbon";
import { ButtonSection } from "./ButtonSection/ButtonSection";
import { AnimationSection } from "./AnimationSection/AnimationSection";
import { CryptoDisclaimer } from "./CryptoDisclaimer/CryptoDisclaimer";
export const HeroBannerWithImagesHomeUplift = ({ title, subtitle, desktopImage, mobileImage, buttonLabel, buttonHref, buttonHrefMobile, buttonOpenInNewTab, linkHref, linkLabel, backgroundColor, actionMessageLink, actionMessageBadge, actionMessageText, actionMessageLinkInNewTab, animationSrc, animationStateMachines, statValues, mobileTitleAlignment, tabletTitleAlignment, desktopTitleAlignment, mobileSubTitleAlignment, tabletSubTitleAlignment, desktopSubTitleAlignment, showArrowRight, cryptoDisclaimerContent }) => {
    const [buttonUrl, setButtonUrl] = useState(buttonHref);
    const { isLarge } = usePageViewStyle();
    const [imageEl, setImageEl] = useState(React.createElement(React.Fragment, null));
    useEffect(() => {
        setImageEl(isLarge ? React.createElement(Img, { src: desktopImage, alt: title, loading: "eager" }) : React.createElement(Img, { src: mobileImage, alt: title, loading: "eager" }));
    }, [isLarge, desktopImage, mobileImage, title]);
    useEffect(() => {
        setButtonUrl((!isLarge && buttonHrefMobile) ? buttonHrefMobile : buttonHref);
    }, [isLarge, buttonHrefMobile, buttonHref]);
    const mainClassName = `${backgroundColor === "White" ? "HeroBannerHomepageUplift white-background" : "HeroBannerHomepageUplift"}`;
    const showActionMessage = () => (actionMessageText && actionMessageLink) &&
        React.createElement("a", { href: actionMessageLink, target: actionMessageLinkInNewTab ? "__blank" : "__self", className: "HeroBannerHomepageUplift__action-message" },
            actionMessageBadge && React.createElement("span", { className: "badge" }, actionMessageBadge),
            actionMessageText,
            IconChevronRight);
    const handleButtonStatsBanner = useMemo(() => {
        if (buttonLabel && buttonHref) {
            return (React.createElement("div", { className: "buttonsWrapper" },
                React.createElement(ButtonSection, { showArrowRight: showArrowRight, buttonLabel: buttonLabel, buttonHref: buttonUrl, linkHref: linkHref, linkLabel: linkLabel, buttonOpenInNewTab: buttonOpenInNewTab })));
        }
        return (Array.isArray(statValues) &&
            React.createElement("div", { className: "options-wrapper" }, statValues.map((a) => (React.createElement(StatsValuesRibbon, { key: `unique${a.title}`, ...a })))));
    }, [buttonHref, buttonLabel, buttonOpenInNewTab, buttonUrl, linkHref, linkLabel, showArrowRight, statValues]);
    return (React.createElement("div", { className: mainClassName },
        React.createElement(ContainerFW, { className: "g-container--new" },
            React.createElement("div", { className: "streaks-container" },
                React.createElement("div", { className: "streaks" },
                    React.createElement("div", { className: "streak1" }),
                    React.createElement("div", { className: "streak2" }),
                    React.createElement("div", { className: "streak3" }))),
            React.createElement("div", { className: "HeroBannerHomepageUplift__container" },
                React.createElement(Container, { className: "g-container--new" },
                    React.createElement("div", { className: "HeroBannerHomepageUplift__content" },
                        showActionMessage(),
                        React.createElement("h1", { className: "h1-v2dot6 titleWrapper__title", dangerouslySetInnerHTML: {
                                __html: title,
                            }, style: {
                                "--mobile-title-alignment": mobileTitleAlignment,
                                "--tablet-title-alignment": tabletTitleAlignment,
                                "--desktop-title-alignment": desktopTitleAlignment
                            } }),
                        React.createElement("div", { className: "titleWrapper__subtitle", dangerouslySetInnerHTML: {
                                __html: subtitle,
                            }, style: {
                                "--mobile-subTitle-alignment": mobileSubTitleAlignment,
                                "--tablet-subTitle-alignment": tabletSubTitleAlignment,
                                "--desktop-subTitle-alignment": desktopSubTitleAlignment
                            } }),
                        handleButtonStatsBanner,
                        cryptoDisclaimerContent && React.createElement(CryptoDisclaimer, { content: cryptoDisclaimerContent })),
                    React.createElement(AnimationSection, { imageEl: imageEl, animationSrc: animationSrc, animationStateMachines: animationStateMachines }))))));
};
