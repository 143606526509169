import React from "react";
import { Heading } from "../../Heading";
import { ImgV2 } from "../../ImgV2";
export const Listitem = ({ title, subTitle, iconUrl }) => (React.createElement("div", { className: "list-item__root" },
    React.createElement(ImgV2, { src: iconUrl }),
    React.createElement("div", { className: "list-item__root__textsWrapper" },
        React.createElement(Heading, { design: "h5-bold" }, title),
        React.createElement("div", { className: "list-item__root__textsWrapper__subtitle", dangerouslySetInnerHTML: {
                __html: subTitle || ""
            } }))));
