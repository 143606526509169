import React from "react";
import { Container, ContainerFW } from "../../../header";
import { usePageViewStyle } from "../../../hooks";
import { MobileSmallAwardCard } from "./MobileSmallAwardCard/MobileSmallAwardCard";
import { SmallAwardCard } from "./SmallAwardCard/SmallAwardCard";
import { getColors } from "../../../utils";
export const SmallAwardCardsDeck = ({ awardCards, title, titleAlignment, backgroundColor }) => {
    const { isSmall } = usePageViewStyle();
    const { bgColor } = getColors(backgroundColor);
    return (React.createElement(ContainerFW, { className: "g-container--new", backgroundColor: bgColor },
        React.createElement(Container, { className: "g-container--new" },
            React.createElement("div", { className: "small-award-cards-deck-root" },
                title && React.createElement("div", { className: "small-award-cards-deck-root__title h3-semi-bold-small", dangerouslySetInnerHTML: {
                        __html: title,
                    }, style: { "--title-alignment": titleAlignment } }),
                React.createElement("div", { className: "awards-wrapper" }, awardCards.length > 0 && awardCards.map((award) => (React.createElement(React.Fragment, null, isSmall ? React.createElement(MobileSmallAwardCard, { key: award.title, title: award.title, subtitle: award.subtitle, mobileAwardImage: award.mobileAwardImage, date: award.date }) : React.createElement(SmallAwardCard, { key: award.title, date: award.date, title: award.title, subtitle: award.subtitle, leftLeavesImage: award.leftLeavesImage, rightLeavesImage: award.rightLeavesImage })))))))));
};
