import React from "react";
import { Container, ContainerFW } from "../../global";
import { BigAwardsCard } from "./BigAwardsCard";
export const BigAwardsCardDeck = ({ title, titleColor, cards, backgroundColor, }) => (React.createElement(ContainerFW, { backgroundColor: backgroundColor },
    React.createElement(Container, null,
        React.createElement("div", { className: "big-awards-card-deck" },
            title && (React.createElement("h2", { style: {
                    "--title-color": `${titleColor}`,
                } }, title)),
            Array.isArray(cards) &&
                cards.map((card, index) => (React.createElement(BigAwardsCard, { key: index, awardImgUrl: card.awardImgUrl, title: card.title, subTitle: card.subTitle, year: card.year, design: card.design, backgroundImg: card.backgroundImg, topIconPosition: card.topIconPosition, firstGradientColor: card.firstGradientColor, secondGradientColor: card.secondGradientColor })))))));
