import React, { useState, useEffect } from "react";
import { ContainerFW, Container } from "../global";
import { ImgV2 } from "../ImgV2/ImgV2";
import { getColors, getClassConditionalAppend, getContainerClassPerMaxWidth } from "../../utils";
import { FaqAccordionList } from "../FaqAccordionList/FaqAccordionList";
export const FaqAccordionListWithImage = ({ image, imageAlt, containerMaxWidth, ...args }) => {
    const { bgColor, textColor } = getColors(args.backgroundColor || "white");
    const [imageValue, setImageValue] = useState("");
    const [altValue, setAltValue] = useState("");
    useEffect(() => {
        setImageValue(image);
        setAltValue(imageAlt);
    }, [image, imageAlt]);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor, className: containerExtraClass },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("div", { style: { color: textColor }, className: "faq-accordion-list-with-image" },
                React.createElement("div", { className: "faq-accordion-list-with-image__grid" },
                    React.createElement(FaqAccordionList, { ...args }),
                    React.createElement("div", { className: "faq-accordion-list-with-image__image-container centered-container hide-small-ndb hide-medium-ndb" },
                        React.createElement(ImgV2, { largeUrl: imageValue, strictRender: true, alt: altValue })))))));
};
