import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ContainerFW, Container } from "../global";
import { Img } from "../Img";
import Addplus from "../svgIcons/Addplus";
import Substract from "../svgIcons/Substract";
import { getColors, getClassConditionalAppend, getContainerClassPerMaxWidth } from "../../utils";
import { IconChat } from "../svgIcons";
const FaqAccordionCard = ({ id, question, answer, setActiveCardId, activeCardId, bgColor }) => {
    const handleExpand = useCallback(() => {
        if (setActiveCardId && id === activeCardId) {
            setActiveCardId(-1);
        }
        else if (setActiveCardId && id !== undefined) {
            setActiveCardId(id);
        }
    }, [activeCardId, id, setActiveCardId]);
    const [answerValue, setAnswerValue] = useState("");
    useEffect(() => {
        setAnswerValue(answer);
    }, [answer]);
    return (React.createElement("div", { itemScope: true, itemProp: "mainEntity", itemType: "https://schema.org/Question", style: { backgroundColor: bgColor }, className: `faq-accordion-list__faq-card ${id === activeCardId ? "active" : ""}` },
        React.createElement("div", { className: "faq-accordion-list__faq-card-question", onClick: handleExpand },
            React.createElement("div", { className: "flex faq-accordion-list__plus-minus-text" },
                React.createElement("span", { className: "faq-accordion-list__plus" }, Addplus({})),
                React.createElement("span", { className: "faq-accordion-list__minus" }, Substract({})),
                React.createElement("h5", { itemProp: "name" }, question))),
        React.createElement("div", { className: "flex faq-accordion-list__plus-minus-text" },
            React.createElement("span", { className: "faq-accordion-list__minus-stub", style: { visibility: "hidden" } }, Substract({})),
            React.createElement("div", { itemScope: true, itemProp: "acceptedAnswer", itemType: "https://schema.org/Answer" },
                React.createElement("div", { itemProp: "text", className: "faq-accordion-list__faq-card-answer", dangerouslySetInnerHTML: { __html: answerValue } })))));
};
export const FaqAccordionList = ({ title, iconImage, iconAlt, moreQuestions, moreQuestionsLinkText, moreQuestionsLinkUrl, items, backgroundColor, containerMaxWidth }) => {
    const [activeCardId, setActiveCardId] = React.useState(-1);
    const { bgColor, textColor } = getColors(backgroundColor || "white");
    const accordion = useMemo(() => (React.createElement("div", { className: "faq-accordion-list__deck", style: { backgroundColor: bgColor } }, items.map((item, i) => React.createElement(FaqAccordionCard, { key: `faq-accordion-list__card-${i}`, id: i, question: item.question, answer: item.answer, setActiveCardId: setActiveCardId, activeCardId: activeCardId, bgColor: bgColor })))), [items, setActiveCardId, activeCardId, bgColor]);
    const containerMaxWidthClass = getContainerClassPerMaxWidth(containerMaxWidth);
    const containerExtraClass = getClassConditionalAppend(containerMaxWidthClass);
    return (React.createElement(ContainerFW, { backgroundColor: bgColor, className: containerExtraClass },
        React.createElement(Container, { className: containerExtraClass },
            React.createElement("div", { style: { color: textColor }, className: "faq-accordion-list" },
                React.createElement("div", { className: "faq-accordion-list__flex-container" },
                    React.createElement("div", { className: "faq-accordion-list__title-container color-dark-default" },
                        React.createElement("div", { className: "flex faq-accordion-list__title-flex" },
                            React.createElement("div", { className: "faq-accordion-list__image-container" }, iconImage ? React.createElement(Img, { src: iconImage, alt: iconAlt, className: "faq-accordion-list__icon-image", loading: "eager" }) : React.createElement("span", { className: "faq-accordion-list__icon-image" }, IconChat())),
                            React.createElement("div", { className: "centered-container" },
                                React.createElement("h2", { className: "faq-accordion-list__faq-title" }, title))),
                        (moreQuestionsLinkText || moreQuestions) && React.createElement("div", { className: "flex faq-accordion-list__title-flex hide-small hide-medium" },
                            React.createElement("div", { className: "faq-accordion-list__image-container" }),
                            React.createElement("p", null,
                                moreQuestions,
                                React.createElement("a", { href: moreQuestionsLinkUrl },
                                    React.createElement("span", { className: "p-bold" }, moreQuestionsLinkText))))),
                    React.createElement("div", { itemScope: true, itemType: "https://schema.org/FAQPage", style: { backgroundColor: bgColor } }, accordion),
                    React.createElement("div", { className: "hide-large" }),
                    React.createElement("div", { className: "flex faq-accordion-list__title-flex hide-large" },
                        React.createElement("p", null,
                            moreQuestions,
                            React.createElement("a", { href: moreQuestionsLinkUrl },
                                React.createElement("span", { className: "p-bold" }, moreQuestionsLinkText)))))))));
};
