import * as React from "react";
import { getColors } from "../../utils";
import { ButtonV2 } from "../ButtonV2";
import { Container, ContainerFW } from "../global";
import { Img } from "../Img";
import { AnimatedRollingNumber } from "../AnimatedRollingNumber";
export const HeroBannerWithRollingAnimationVideoBG = ({ title, text, buttonText, buttonUrl, buttonDesign = "cta-large-dbg", backgroundColor, videoUrl, rollingItems }) => {
    const { textColor } = getColors(backgroundColor);
    return (React.createElement("div", { className: "hero-banner-with-rolling-animation-video-bg" },
        React.createElement(ContainerFW, null,
            React.createElement(Container, null,
                React.createElement("div", { className: "hero-banner-with-rolling-animation-video-bg", style: { color: textColor } },
                    React.createElement("h1", null, title),
                    React.createElement("p", { dangerouslySetInnerHTML: {
                            __html: text,
                        } }),
                    React.createElement(ButtonV2, { classes: "hero-banner-with-rolling-animation-video-bg__button", href: buttonUrl, design: buttonDesign },
                        buttonText,
                        " "),
                    React.createElement("div", { className: "hero-banner-with-rolling-animation-video-bg__rolling-items-container" }, rollingItems.map((item, i) => (React.createElement("div", { key: i, className: "hero-banner-with-rolling-animation-video-bg__rolling-item" },
                        React.createElement("div", { className: "hero-banner-with-rolling-animation-video-bg__rolling-item-upper flex centered-container" },
                            React.createElement(AnimatedRollingNumber, { classes: "h3-bold", rollTo: item.upperAmount, step: item.upperAmount > 100 ? 5 : 1 }),
                            React.createElement("h3", null, item.staticElement),
                            React.createElement(Img, { alt: item.iconAlt, src: item.iconUrl, style: { width: "40px", marginBottom: "3px" } })),
                        React.createElement("div", { style: { textAlign: "left" }, className: "caption color-dark-20" }, item.description)))))))),
        React.createElement("div", { className: "hero-banner-with-rolling-animation-video-bg__video-container" },
            React.createElement("div", { className: "hero-banner-with-rolling-animation-video-bg__video-mask" }),
            React.createElement("video", { className: "hero-banner-with-rolling-animation-video-bg__video", autoPlay: true, loop: true, muted: true, playsInline: true },
                React.createElement("source", { src: videoUrl, type: 'video/mp4' })))));
};
